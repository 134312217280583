import GlobalLayout from "components/page/GlobalLayout";
import { rhombusSpecs, verkadaSpecs } from "components/vs/lib/comparisonData";
import VsPageUpdated from "components/vs/updated/VsPageUpdated";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function RhombusVsVerkada() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "A Cloud Physical Security Partner You Can Trust",
        paragraph:
          "Thinking of upgrading your security? See why clients choose Rhombus over Verkada, and why users rate us the #1 physical security solution on G2.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        logos: [
          {
            img: data.logo1,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo2,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo3,
            width: "115px",
            alt: "Stryker",
          },
          {
            img: data.logo4,
            width: "186px ",
            alt: "Los Angeles Film School",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },

      benefits: {
        title: "What You Can Expect from Rhombus",
        list: [
          {
            tabTitle: "Trusted Partner",
            preTitle: "Curated, consultative solutions for your organization",
            title: "Your Trusted Security Partner",
            description:
              "Relationships are at the heart of what we do. No matter how big or small your company is, our mission is to build a long-term partnership and provide simple, smart, and powerful solutions that fit your unique needs.",
            image: data.benefit1,
          },
          {
            tabTitle: "Unified Platform",
            preTitle: "Best-in-class performance, reliability, and analytics",
            title: "Powerful Technology on a Unified Cloud Platform",
            description:
              "Rhombus is the central nervous system for physical security management. Easily increase visibility and proactively detect threats. Stay up to date with automatic updates with cutting-edge features at no additional cost.",
            image: data.benefit2,
          },
          {
            tabTitle: "Secure-by-Default",
            preTitle: "Security first with the track record to prove it",
            title: "Data Privacy is in Our DNA",
            description:
              "Founded by cybersecurity experts, Rhombus is built from the ground up to be secure by default. While comparable companies have suffered serious breaches, we safeguard your data so you can enjoy complete security.",
            image: data.benefit3,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "User-Friendly",
          title: "User-Friendly",
          p:
            "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
        },
        {
          icon: data.icon2,
          iconAlt: "AI Analytics",
          title: "AI Analytics",
          p:
            "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.",
        },
        {
          icon: data.icon3,
          iconAlt: "Bandwidth & Latency",
          title: "Bandwidth & Latency",
          p:
            "Get the best performance with 50% less bandwidth utilization and 10x better latency compared to other cloud vendors.",
        },
        {
          icon: data.icon4,
          iconAlt: "Infinite Scalability",
          title: "Infinite Scalability",
          p:
            "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
        },
      ],
      compareTable: {
        title:
          "Rhombus’s Platform Provides Ultimate Flexibility and Best-in-Class Performance",
        rhombus: rhombusSpecs,
        competitor: {
          data: verkadaSpecs,
          logo: data.competitorLogo,
          logoWidth: "127px",
          mobileWidth: "70px",
        },
        keys: [
          "Continuous high-resolution recording and storage",
          "At-rest bandwidth consumption",
          "HD WAN bandwidth consumption",
          "Video Latency",
          "Native offline streaming",
          "Camera Pricing",
          "License pricing",
        ],
      },
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>
            Rhombus vs Verkada - See Why Rhombus is the Trusted Choice
          </title>
          <meta
            name="description"
            content="Discover why Rhombus is the better solution. See how Rhombus outperforms Verkada in performance, bandwidth, cybersecurity, and more."
          />
        </Helmet>
        <VsPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/vs/updated/img/vs-hero-img-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: { eq: "components/vs/updated/img/vs-hero-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(relativePath: { eq: "images/trusted-partner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/vs/updated/img/unified-platform-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/vs/updated/img/data-privacy-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      competitorLogo: file(
        relativePath: { eq: "components/vs/updated/img/Verkada.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
